import React from 'react';
import PropTypes from 'prop-types';
import RaiseOperativeRequestIconButton from '@fingo/lib/views/operativeRequest/RaiseOperativeRequestIconButton';
import { CurrencyFilter } from '@fingo/lib/components/filters';
import UploadSignatureDialog from './UploadSignatureDialog';

const PendingSignatureActions = ({ companyId, selectedDocuments, data, currency, setCurrency }) => (
  <>
    <RaiseOperativeRequestIconButton
      selectedInvoices={selectedDocuments}
      setDocumentIds={() => {}}
    />
    <UploadSignatureDialog
      companyId={companyId}
      selectedDocuments={selectedDocuments}
      invoices={data}
      currency={currency}
    />
    <CurrencyFilter currency={currency} setCurrency={setCurrency} />
  </>
);

PendingSignatureActions.propTypes = {
  companyId: PropTypes.string.isRequired,
  selectedDocuments: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    amountWithIva: PropTypes.shape,
  })).isRequired,
  currency: PropTypes.string.isRequired,
  setCurrency: PropTypes.func.isRequired,
};

export default PendingSignatureActions;
