/* eslint-disable arrow-body-style */
import { useMutation } from '@apollo/client';
import RaiseOperativeRequestIconButton from '@fingo/lib/views/operativeRequest/RaiseOperativeRequestIconButton';
import { useSnackBars } from '@fingo/lib/hooks';
import PropTypes from 'prop-types';
import React from 'react';
import { LoadingButton } from '@mui/lab';
import { UPDATE_REVIEW_DOCUMENTS_MANAGER } from '../../../graphql/ratification/mutations';
import { GET_PENDING_DOCUMENTS_REVIEW_INVOICES } from '../../../graphql/ratification/queries';

const RatificationDocumentsReviewActions = ({
  resolutions,
  selectedInvoiceIds,
  clear,
}) => {
  const newStates = Object.keys(resolutions).map((offerId) => ({
    offerId,
    hesReviewState: resolutions[offerId].hesReviewState,
    ocReviewState: resolutions[offerId].ocReviewState,
    portalScreenshotReviewState: resolutions[offerId].portalScreenshotReviewState,
  })).sort((a, b) => Number(a.offerId) - Number(b.offerId));
  const { addAlert } = useSnackBars();
  const [updateReviewDocumentsManager, { loading }] = useMutation(UPDATE_REVIEW_DOCUMENTS_MANAGER, {
    variables: { newStates },
    onCompleted: () => {
      clear();
      addAlert({
        id: 'update-review-documents-manager',
        message: 'Se resolvieron las revisiones correspondientes',
      });
    },
    onError: () => {
      addAlert({
        id: 'update-review-documents-manager-error',
        message: 'Hubo un error, comunícate con el equipo de desarrollo',
      });
    },
    refetchQueries: [GET_PENDING_DOCUMENTS_REVIEW_INVOICES],
  });

  return (
    <>
      <RaiseOperativeRequestIconButton
        selectedInvoices={selectedInvoiceIds}
        clear={clear}
      />
      <LoadingButton
        color="primary"
        size="small"
        variant="contained"
        onClick={updateReviewDocumentsManager}
        disabled={!selectedInvoiceIds.length}
        loading={loading}
      >
        Enviar seleccionadas
      </LoadingButton>
    </>
  );
};

RatificationDocumentsReviewActions.propTypes = {
  resolutions: PropTypes.shape().isRequired,
  selectedInvoiceIds: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  clear: PropTypes.func.isRequired,
};

export default RatificationDocumentsReviewActions;
