import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FingoDataGrid } from '@fingo/lib/components/dataGrids';
import FingoMainView from '@fingo/lib/components/layout/FingoMainView';
import { useInvoiceLucilaCustomColumns } from '@fingo/lib/constants';
import useInvoicePreColumns from '@fingo/lib/constants/invoice-pre-columns';
import { PENDING_SIGNATURE } from '@fingo/lib/graphql';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PendingSignatureActions from './actions/PendingSignatureActions';
import useCustomPendingSignatureColumns from './columns/CustomPendingSignatureColumns';

const PendingSignatureCompany = ({ id }) => {
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [currency, setCurrency] = useState('');
  const includingColumns = [
    'folio-invoice-profile',
    'dateIssued',
    'receiver_sortable',
    'amountWithIva',
    'deleteAnnexFile',
    'annexFile',
    'executiveAssigned',
  ];
  const columns = useInvoicePreColumns(useInvoiceLucilaCustomColumns().concat(
    useCustomPendingSignatureColumns,
  ));

  return (
    <Stack sx={{ height: 410 }}>
      <FingoMainView
        id="factoring-evaluation-invoices"
        query={PENDING_SIGNATURE}
        queryCustomVariables={{
          companyId: id,
          hasPendingSignature: true,
          currency,
        }}
        slots={{
          actions: PendingSignatureActions,
          table: FingoDataGrid,
        }}
        slotProps={{
          header: { viewTitle: 'Evaluaciones' },
          table: {
            checkboxSelection: true,
            columns,
            hideFooter: true,
            initialOrderBy: '-amountWithIva',
            noRowsMessage: () => <Typography>Sin evaluaciones</Typography>,
            onSelectionModelChange: setSelectedDocuments,
            includeHeaders: includingColumns,
            mobileHeaders: [
              'folio',
            ],
            rowsPerPageOptions: [100, 150, 200],
            disabledCheckboxTooltip: 'No se puede',
          },
          actions: { companyId: id, selectedDocuments, currency, setCurrency },
        }}
      />
    </Stack>
  );
};
PendingSignatureCompany.propTypes = {
  id: PropTypes.string.isRequired,
};

export default PendingSignatureCompany;
