import React from 'react';
import { PaperHeader } from '@fingo/lib/components/headers';
import FingoMainView from '@fingo/lib/components/layout/FingoMainView';
import FingoTable from '@fingo/lib/components/tables/FingoTable';
import { useMasterEntityPreColumns } from '@fingo/lib/constants';
import { GET_PROCESSING_COMPANIES } from '@fingo/lib/graphql';
import { useGetCountryFromUrl } from '@fingo/lib/hooks';
import Typography from '@mui/material/Typography';
import PendingSignatureCompany from './PendingSignatureCompany';

const PendingSignatureManager = () => {
  const country = useGetCountryFromUrl();

  const includingColumns = [
    'name',
  ];

  return (
    <FingoMainView
      type="available-factoring"
      query={GET_PROCESSING_COMPANIES}
      queryCustomVariables={{
        company_Invoice_Status: 'Processing',
        countryId: country?.id,
        distinct: true,
      }}
      slots={{
        header: PaperHeader,
        table: FingoTable,
      }}
      slotProps={{
        header: {
          viewTitle: 'Pendientes de firma',
          finder: {
            searchPlaceHolder: 'Buscar empresa',
          },
        },
        table: {
          includeHeaders: includingColumns,
          columns: useMasterEntityPreColumns(),
          checkboxSelection: false,
          isRowSelectable: () => true,
          collapsible: true,
          isRowCollapsible: () => true,
          collapseComponent: ({ row }) => <PendingSignatureCompany id={row.id} />,
          disableSelectionOnClick: true,
          noRowsMessage: () => <Typography>No hay facturas pendientes de firma</Typography>,
          rowsPerPageOptions: [10, 15],
        },
      }}
    />
  );
};

export default PendingSignatureManager;
